.form {
  /* border: 2px solid black; */
  width: 80%;
  justify-content: center;
  /* align-items: center; */
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  font-size: small;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: .3s
  /* margin: auto; */
}


@media only screen and (max-width: 1025px) {
  .form {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: none;
    transition: none;
    width: 99%;
  }
}
