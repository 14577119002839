* {
  padding: 0px;
  list-style-type: none;
  text-decoration: none;
}

.footerMenu a {
  padding: 1rem;
  color: #999;
}

@media (max-width: 700px) {
  .footerMenu a {
    padding: 5px;
  }
}

.footerMenu a :hover {
  color: #6eb48c;
}

.topButton {
  margin-top: 20px;
  margin-bottom: 10px;
  background: transparent;
  color: #999;
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.4s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topButton :hover {
  color: #fff !important;
  cursor: pointer;
}
