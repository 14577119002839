.cta-input {
  display: flex;
  flex-direction: column;
  padding: 1vw;
}

.cta {
  width: 100%;
  overflow-x: hidden;
  /* font-size: 1vw; */
}

.cta-input > label {
  padding: 0.5vw;
  background-color: white;
  text-transform: uppercase;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 2px 2px 0px 0px;
}

.cta-input > input {
  background-color: #f7f7f7;
  color: black;
  padding: 0.5vw;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 2px 2px;
  font-size: medium;
  width: 100%;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.color-input {
  display: flex;
  flex-direction: row;
}

.color-input > input {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 2px 2px;
  font-size: medium;
  width: 100%;
}

.select-input {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 2px 2px;
  font-size: medium;
  padding: 0.5vw;
}

input[type="radio"] {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */

  /* border-radius: 50%; */
  margin-left: 1rem;
  width: 25px;
  height: 25px;

  border: 1px solid #666;
  color: #0385db;
  transition: 0.2s all linear;
  margin-right: 5px;
  padding: 40px;

  /* position: relative;
  top: 4px; */

  vertical-align: middle;
}

input:checked {
  background-image: radial-gradient(#0385db 50%, white 50%);
  /* background-size: 50%; */
  outline: unset !important;
}


.radioButton {
  font-size: 15px;
}

.radioButton input {
  font-size: large;
  padding: 0.5vw;
  line-height: 1.6;
  margin-left: 0.7vw;
  vertical-align: middle;
}

.radioButton {
  display: flex;
  flex-direction: row;
  line-height: 2;
  padding: 0.5vw;
}

.cta .MuiButton-contained {
  border-radius: 25px !important;
  background-color: #0385db !important;
  color: white !important;
  width: 100% !important;
  text-transform: capitalize !important;
}

.cta .MuiButton-contained:hover {
  background-color: #181b3a !important;
}

.cta p {
  font-size: smaller;
  padding-left: 1vw;
}

@media only screen and (max-width: 1025px) {
  .cta-input {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  .cta {
    width: 100%;
    overflow-x: hidden;
    font-size: 1em;
  }

  .cta-input > label {
    padding: 0.5em;
    background-color: white;
    text-transform: uppercase;
    color: black;
    border: 1px solid #dddddd;
    border-radius: 2px 2px 0px 0px;
  }

  .cta-input > input {
    background-color: #f7f7f7;
    color: black;
    padding: 0.5em;
    border: 1px solid #dddddd;
    border-radius: 0px 0px 2px 2px;
    font-size: medium;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  select:focus {
    outline: none;
  }

  .color-input {
    display: flex;
    flex-direction: row;
  }

  .color-input > input {
    background-color: #f7f7f7;
    color: black;
    border: 1px solid #dddddd;
    border-radius: 0px 0px 2px 2px;
    font-size: medium;
    width: 100%;
  }

  .select-input {
    background-color: #f7f7f7;
    color: black;
    border: 1px solid #dddddd;
    border-radius: 0px 0px 2px 2px;
    font-size: medium;
    padding: 0.5em;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 0.5em;
    height: 0.5em;

    border: 1px solid #666;
    color: #0385db;
    transition: 0.2s all linear;
    margin-right: 5px;
    padding: 20px;

    position: relative;
    top: 4px;

    vertical-align: middle;
  }

  input:checked {
    background-image: radial-gradient(#0385db 50%, white 50%);
    /* background-size: 50%; */
    outline: unset !important;
  }

  .radioButton input {
    font-size: large;
    padding: 0.5em;
    line-height: 1.6;
    margin-left: 0.7em;
    vertical-align: middle;
  }

  .radioButton {
    display: flex;
    flex-direction: row;
    line-height: 2;
    padding: 0.5em;
  }

  .cta .MuiButton-contained {
    border-radius: 25px !important;
    background-color: #0385db !important;
    color: white !important;
    width: 100% !important;
    text-transform: capitalize !important;
  }

  .cta .MuiButton-contained:hover {
    background-color: #181b3a !important;
  }

  .cta p {
    font-size: smaller;
    padding-left: 1em;
  }
}
