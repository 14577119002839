.share-container {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	padding: 100px 0;
	margin-top: 5rem;
}

.bookmark {
	margin-right: 100px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}

.bookmark img {
	width: 30px;
}

.bookmark h3{
	margin-bottom: 29px;
}

.share-links {
	margin-left: 100px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}

.share-links h3{
	margin-bottom: 18px;
}

.share-buttons {
	display: flex;
	flex-flow: row;
}

.share-buttons i {
	margin: 0 10px;
}

.fab {
	margin: 0;
	padding: 0;
}

.facebook:hover {
	cursor: pointer;
	color: #3d5a98;
}

.twitter:hover {
	cursor: pointer;
	color: #50abf1;
}

.linkedin:hover {
	cursor: pointer;
	color: #0084b1;
}

.sharecontainer-line {
	height: 80px;
	border-left: 1px solid black;
}

@media (max-width: 800px) {

	.share-container {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		background-color: #f5f5f5;
		padding: 20px 0;
		text-align: center;
	}

	.sharecontainer-line {
		display: none;
	}

	.bookmark {
		margin: 0;
	}

	.share-links {
		margin: 40px;
	}
}
