.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* box-shadow: 5px 0px 0px 3px #DDDDDD; */
  overflow: hidden;
  border-bottom: 1px solid #dddddd;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  margin: 0px;
  padding: 0px;
}

.image {
  padding: 12px;
  height: 55px;
}

.list {
  display: flex;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 885px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* box-shadow: 5px 0px 0px 3px #DDDDDD; */
    overflow: hidden;
    border-bottom: 1px solid #dddddd;
    margin: 0px;
    padding: 0px;
  }
  .image {
    padding: 12px;
    height: 40px;
  }
  .list {
    display: none;
    margin: 0px;
    padding: 0px;
  }
}

.list-item {
  padding: 0px 15px;
}

.link {
  line-height: 80px;
  text-decoration: none;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #313131;
}

.header a:hover {
  background-color: #ececec;
  color: #6eb48c;
}
